import React, { useEffect, useState } from 'react'
import client from '../utils/shopify'
import "../css/Register.css"
import CURSOR from "../assets/cursor.png"
import Fade from 'react-reveal/Fade'
import { Link, useNavigate } from 'react-router-dom'
import { InfoOutlined } from '@mui/icons-material'
import { BiSolidHide } from "react-icons/bi";
import { BiSolidShow } from "react-icons/bi";

function Register() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('')
  const [emailValid, setEmailValid] = useState(null)
  const [firstNameValid, setFirstNameValid] = useState(null)
  const [lastNameValid, setLastNameValid] = useState(null)
  const [passwordValid, setPasswordValid] = useState(null)
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(null)
  const [acceptsMarketing, setAcceptsMarketing] = useState(true)
  const [loading, setLoading] = useState(false);

  const registerUser = `
  mutation MyMutation($input: CustomerCreateInput!, $email: String!, $password: String!) {
  customerCreate(input: $input) {
    customer {
      id
      email
      createdAt
      firstName
      displayName
      phone
      acceptsMarketing
      updatedAt
      numberOfOrders
      orders(first: 10) {
        nodes {
          name
          orderNumber
        }
      }
    }
    customerUserErrors {
      field
      message
    }
  }

  customerAccessTokenCreate(input: {email: $email, password: $password}) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      field
      message
    }
  }
}`

  /** customerAccessTokenCreate(input: {email: $email, password: $password}) {
      customerAccessToken {
        accessToken
        expiresAt
      }
    } */

  const createUser = async () => {
    setLoading(true)
    const { data, error } = await client.request(registerUser, {
      variables: {
        input: {
          email: email,
          firstName: firstName,
          lastName: lastName,
          password: password,
          acceptsMarketing: acceptsMarketing
        },
        email: email,
        password: password
      }
    })
    console.log(data)
    if (email === '') { setEmailValid('invalid') } else { setEmailValid(null) }
    if (password === '') { setPasswordValid('invalid') } else { setPasswordValid(null) }
    if (firstName === '') { setFirstNameValid('invalid') } else { setFirstNameValid(null) }
    if (lastName === '') { setLastNameValid('invalid') } else { setLastNameValid(null) }
    if (confirmPassword === '') { setConfirmPasswordValid('invalid') } else { setConfirmPasswordValid(null) }

    if (firstName !== '' && lastName !== '' && email !== '' && password !== '' && confirmPassword !== '') {
      if (password === confirmPassword) {
        try {
          const { data, error } = await client.request(registerUser, {
            variables: {
              input: {
                email: email,
                firstName: firstName,
                lastName: lastName,
                password: password,
                acceptsMarketing: acceptsMarketing
              },
              email: email,
              password: password
            }
          })
          console.log(data)
          localStorage.setItem('customerAccessToken', data['customerAccessTokenCreate']['customerAccessToken']['accessToken'])
          localStorage.setItem('expiresAt', data['customerAccessTokenCreate']['customerAccessToken']['expiresAt'])
          await navigate('/profile')
          setLoading(false)
          console.log(error)
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      } else {
        console.log('PASSWORD Does not match ')
      }
    } else {
      console.log('Fill all logs')
    }
  }


  useEffect(() => {
    if (localStorage.getItem('customerAccessToken') !== null) {
      navigate('/profile')
    }
  }, [])

  if (loading) return (
    <div className='loader'>
      <img alt='cursor' src={CURSOR} />
    </div>
  )

  return (
    <div className='register__page'>
      <Fade bottom duration={1000}>
        <form onSubmit={(e) => { e.preventDefault(); createUser() }}>
          <p>REGISTER</p>
          <span style={{ marginBottom: '25px' }}>Join our community</span>
          <div className='inputs__container'>
            <div className='names__container'>
              <label style={{ color: firstNameValid === null ? 'black' : 'red' }}>
                FIRST NAME
                <input type='text' style={{ marginRight: '5px' }} onChange={(e) => setFirstName(e.target.value)} />
                {firstNameValid === null ? <></> : <span style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '8px' }}>
                  <InfoOutlined style={{ height: '20px' }} /> Required Field</span>}
              </label>
              <label style={{ color: lastNameValid === null ? 'black' : 'red' }}>LAST NAME <input type='text' onChange={(e) => setLastName(e.target.value)} />{lastNameValid === null ? <></> : <span style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '8px' }}><InfoOutlined style={{ height: '20px' }} /> Required Field</span>} </label>
            </div>
            <label style={{ color: emailValid === null ? 'black' : 'red' }}>EMAIL<input type='text' onChange={(e) => setEmail(e.target.value)} />{emailValid === null ? <></> : <span style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '8px' }}><InfoOutlined style={{ height: '20px' }} /> Required Field</span>} </label>
            <label style={{ color: passwordValid === null ? 'black' : 'red' }}>
              PASSWORD
             <div style={{display: 'flex'}}>
              <input className='input__class'  type={showPassword ? 'text':'password'} onChange={(e) => setPassword(e.target.value)} />
              <button className='showpass' onClick={(e)=>{e.preventDefault();setShowPassword(!showPassword)}}>{showPassword ? <BiSolidShow size={17}/> : <BiSolidHide size={17}/>}</button>
              </div>
              {passwordValid === null ? <></> : <span style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '8px' }}>
                <InfoOutlined style={{ height: '20px' }} /> Required Field</span>}
            </label>
            <label style={{ color: confirmPasswordValid === null ? 'black' : 'red' }}>
              CONFIRM PASSWORD
              <div style={{display: 'flex'}}>
              <input  className='input__class' type={showConfirmPassword ? 'text':'password'} onChange={(e) => setConfirmPassword(e.target.value)} />
              <button className='showpass' onClick={(e)=>{e.preventDefault(); setShowConfirmPassword(!showConfirmPassword)}}>{showConfirmPassword ? <BiSolidShow size={17}/> : <BiSolidHide size={17}/>}</button>
              </div>
              {confirmPasswordValid === null ? <></> : <span style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '8px' }}>
                <InfoOutlined style={{ height: '20px' }} /> Required Field</span>}
            </label>
            <p onClick={() => setAcceptsMarketing(!acceptsMarketing)} className='newsletter'><input type='checkbox' checked={acceptsMarketing} onChange={(e) => setAcceptsMarketing(e.currentTarget.checked)} />I would like to receive the 2WENT6EX newsletter</p>
          </div>
          <button style={{ width: '100%', padding: '14px', marginBottom: '20px', color: 'white', background: 'black', border: 'none' }}>CREATE MY ACCOUNT</button>
          <span>Already a member<span className='questionmark' style={{marginLeft: '0px'}}>?</span>  <Link style={{ color: 'black' }} to={'/login'}>LOG IN</Link></span>
        </form>
      </Fade>

    </div>
  )
}

export default Register