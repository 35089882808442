import React, { useEffect, useState } from 'react'
import "../css/FaqPage.css"
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import bank from "../assets/payment_icon/bank.webp"
import bhim from "../assets/payment_icon/bhim.webp"
import gpay from "../assets/payment_icon/gpay.webp"
import master from "../assets/payment_icon/master.png"
import net from "../assets/payment_icon/net.webp"
import paytm from "../assets/payment_icon/paytm.png"
import phonepe from "../assets/payment_icon/phonepe.png"
import visa from "../assets/payment_icon/visa.png"

function FaqPage() {

  const [width, setWidth] = useState(window.innerWidth);
  const [expanded, setExpanded] = useState('');
  const [expandedQ, setExpandedQ] = useState('');
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeQ = (panel) => (event, isExpandedQ) => {
    setExpandedQ(isExpandedQ ? panel : false);
  };


  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useGSAP(() => {
    var tl = gsap.timeline({ defaults: { duration: 2, ease: "none" } });

    tl.from("#scramble", { duration: 1, y: window.innerHeight / 4.2, opacity: 0, ease: 'back.inOut' })

    tl.play()
  })

  return (
    <div className='faqpage'>
      <div className='faq__section1'>
        <p id="scramble" style={{ fontSize: width >= 1000 ? "250px" : "100px" }}>FAQ'S</p>
      </div>
      <div className='faq__section2'>
        <div className='faq__quest'>
          <Accordion expanded={expandedQ === 'panelA'} className='faqaccord Hq' onChange={handleChangeQ('panelA')} >
            <AccordionSummary
              expandIcon={expandedQ === 'panelA'? <Close /> : <Add />}
            > <h1>SHIPPING AND DELIVERY</h1>
            </AccordionSummary>
            <Accordion expanded={expanded === 'panel1'} className='faqaccord' onChange={handleChange('panel1')} >
              <AccordionSummary
                expandIcon={expanded === 'panel1' ? <Close /> : <Add />}
              >
                How many days does it take for my order to arrive<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                Shipping usually takes between 4-5 business days, depending on your
                location, but may take up to 7 days for some destinations.
                This time is subject to change due to the nature of production and heavy
                order volumes.
                Pre-order, Made to Order and DIY items will ship on the estimated date
                noted on the product description page. These items will ship through
                Express once they become available.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} className='faqaccord' onChange={handleChange('panel2')} >
              <AccordionSummary expandIcon={expanded === 'panel2' ? <Close /> : <Add />}>
              <p style={{display: 'inline'}}> Is 2WENT6EX going to cover the shipping fees for my order<p className='questionmark'>?</p></p>
              </AccordionSummary>
              <AccordionDetails>
                Complimentary express shipping on all orders. Please note that free
                shipping will be applied to your order at checkout.
                International orders will be charged shipping depending on their area pin
                code.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} className='faqaccord' onChange={handleChange('panel3')} >
              <AccordionSummary expandIcon={expanded === 'panel3' ? <Close /> : <Add />}>
                Can I change the shipping method<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                If you need to modify your shipping or delivery information after your order has been
                placed, please <Link style={{color: '#06c'}} to='/contactus'>Contact Us</Link> as soon as possible. Shipping information, including
                delivery address, cannot be modified once the order has already been shipped.
                Please note, shipping and delivery information for orders purchased through Razor
                pay may not be modified once the order has been submitted.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} className='faqaccord' onChange={handleChange('panel4')} >
              <AccordionSummary expandIcon={expanded === 'panel4' ? <Close /> : <Add />}>
                Can my order be delayed<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                Due to unforeseen circumstances your order can be delayed but our team will
                update you in such situations where the delay is beyond 12-15 days
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} className='faqaccord' onChange={handleChange('panel5')} >
              <AccordionSummary expandIcon={expanded === 'panel5' ? <Close /> : <Add />}>
              <p style={{display: 'inline'}}>My order shows as having been delivered, but I haven’t received it. What can I do<p className='questionmark'>?</p></p>
              </AccordionSummary>
              <AccordionDetails>
                In such situations, you must reach out to the courier partner and schedule a delivery again if
                the order is not delivered but mentioned as delivered.
                2WENT6EX is not responsible for circumstances like this.
              </AccordionDetails>
            </Accordion>
          </Accordion>
          <Accordion expanded={expandedQ === 'panelB'} className='faqaccord Hq' onChange={handleChangeQ('panelB')} >
            <AccordionSummary
              expandIcon={expandedQ === 'panelB' ? <Close /> : <Add />}
            > <h1>RETURNS AND EXCHANGE</h1>
            </AccordionSummary>
            <Accordion expanded={expanded === 'panel6'} className='faqaccord' onChange={handleChange('panel6')} >
              <AccordionSummary expandIcon={expanded === 'panel6' ? <Close /> : <Add />}>
                Can I return or exchange an item<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                At this moment, we are not accepting any returns on orders.
                All our orders are personally scanned before delivery to avoid any faulty or
                damaged product.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} className='faqaccord' onChange={handleChange('panel7')} >
              <AccordionSummary expandIcon={expanded === 'panel7' ? <Close /> : <Add />}>
                When do I get my refund<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                In case of refund, 2WENT6EX gives all members store credit which they can use
                for purchases in the future.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel8'} className='faqaccord' onChange={handleChange('panel8')} >
              <AccordionSummary expandIcon={expanded === 'panel8' ?  <Close /> : <Add />}>
                I have return/exchange. What should I do now<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                Feel free to contact our relations manager to assess you further on
                this query.
                Our advisors are available mon-sat 10am-7pm
              </AccordionDetails>
            </Accordion>
          </Accordion>
          <Accordion expanded={expandedQ === 'panelC'} className='faqaccord Hq' onChange={handleChangeQ('panelC')} >
            <AccordionSummary
              expandIcon={expandedQ === 'panelC' ? <Close /> : <Add />}
            > <h1>ORDERS AND TRACKING</h1>
            </AccordionSummary>
            <Accordion expanded={expanded === 'panel9'} className='faqaccord' onChange={handleChange('panel9')} >
              <AccordionSummary expandIcon={expanded === 'panel9' ? <Close /> : <Add />}>
                Where is my order<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                Once your order has been approved, you will receive an email confirmation with
                your shipping details and a tracking number.
                Please note that it can take up to 24 hours before the courier updates the
                shipping information after you have received the shipping confirmation email
                from us.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel10'} className='faqaccord' onChange={handleChange('panel10')} >
              <AccordionSummary expandIcon={expanded === 'panel10' ? <Close /> : <Add />}>
                <p style={{display: 'inline'}}>Can I change or update my order<p className='questionmark'>?</p>Add items to my order<div className='questionmark'>?</div></p></AccordionSummary>
              <AccordionDetails>
                An order cannot be changed once the merchandise has been processed by our
                warehouse. Before our warehouse has started processing the order, we can add more
                items to your order but make sure to have the correct order number along with it.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel11'} className='faqaccord' onChange={handleChange('panel11')} >
              <AccordionSummary expandIcon={expanded === 'panel11' ?  <Close /> : <Add />}>
                Can I cancel my order<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                Once the order is processed you cannot cancel the order since all our orders are
                processed immediately as soon as 2WENT6EX warehouse receives it.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel12'} className='faqaccord' onChange={handleChange('panel12')} >
              <AccordionSummary expandIcon={expanded === 'panel12' ? <Close /> : <Add />}>
              <p style={{display: 'inline'}}>I have added incorrect shipping address to my order. How can I change it<p className='questionmark'>?</p></p>
              </AccordionSummary>
              <AccordionDetails>
                If you wish to change your shipping address, please contact our Client Services team.
                Please note your order's shipping address can only be changed before the order has shipped.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel13'} className='faqaccord' onChange={handleChange('panel13')} >
              <AccordionSummary expandIcon={expanded === 'panel13' ? <Close /> : <Add />}>
                How can I pay for my order<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{display: 'flex',alignItems: 'center'}}>
                  <img width={100}  src={bank}/>
                  <img width={100}  src={bhim}/>
                  <img width={100}  src={gpay}/>
                  <img width={100}  src={master}/>
                  <img width={100}  src={net}/>
                  <img width={100}  src={paytm}/>
                  <img width={100}  src={phonepe}/>
                  <img width={100}  src={visa}/>

                </div>
                2WENT6EX accepts the following payment methods:
                UPI
                You can generate QR code and scan to pay via Google pay, PhonePe, Paytm, BHIM.
                Credit card
                You can pay by Visa, Mastercard and American Express. Visa and Mastercard are verified
                by a secure code. Payment is debited from your card once you place the order.
                Net banking
                Securely pay via net banking to avoid any hassle. Payment is debited from bank once you
                place the order.
              </AccordionDetails>
            </Accordion>
          </Accordion>
          <Accordion expanded={expandedQ === 'panelD'} className='faqaccord Hq' onChange={handleChangeQ('panelD')} >
            <AccordionSummary
              expandIcon={expandedQ === 'panelD' ? <Close /> : <Add />}
            > <h1>SIZE AND FIT</h1>
            </AccordionSummary>
            <Accordion expanded={expanded === 'panel14'} className='faqaccord' onChange={handleChange('panel14')} >
              <AccordionSummary expandIcon={expanded === 'panel14' ? <Close /> : <Add />}>
                What size do you use<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                All our clothing is based on Indian sizing, for any doubts please refer to our charts
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel15'} className='faqaccord' onChange={handleChange('panel15')} >
              <AccordionSummary expandIcon={expanded === 'panel15' ? <Close /> : <Add />}>
                Where can I find the measurement of product<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                For most items online, we have the exact garment measurements stated.
                Go to size guide / garment measurements on the specific item you are interested in,
                and you’ll find the measurements for that specific item.
                If an item is missing garment measurements, please contact our Client Services team
                who would be happy to provide them to you.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel16'} className='faqaccord' onChange={handleChange('panel16')} >
              <AccordionSummary expandIcon={expanded === 'panel16' ? <Close /> : <Add />}>
                Do you have a size guide<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                Yes, please refer to our size guide below for both women and men.
              </AccordionDetails>
            </Accordion>
          </Accordion>
          <Accordion expanded={expandedQ === 'panelE'} className='faqaccord Hq' onChange={handleChangeQ('panelE')} >
            <AccordionSummary
              expandIcon={expandedQ === 'panelE' ? <Close /> : <Add />}
            > <h1>PAYMENT METHOD</h1>
            </AccordionSummary>
            <Accordion expanded={expanded === 'panel17'} className='faqaccord' onChange={handleChange('panel17')} >
              <AccordionSummary expandIcon={expanded === 'panel17' ? <Close /> : <Add />}>
              <p style={{display: 'inline'}}>Why can’t I select cash on delivery as my mode of payment<p className='questionmark'>?</p></p>
              </AccordionSummary>
              <AccordionDetails>
                2WENT6EX only accepts online payment methods as of now. For any assistance on
                COD, please contact client advisors.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel18'} className='faqaccord' onChange={handleChange('panel18')} >
              <AccordionSummary expandIcon={expanded === 'panel18' ? <Close /> : <Add />}>
                When will I be charged for my order<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                Once you proceed from 2WENT6EX cart to checkout then payment is debited from
                your card once you place the order.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel19'} className='faqaccord' onChange={handleChange('panel19')} >
              <AccordionSummary expandIcon={expanded === 'panel19' ? <Close /> : <Add />}>
              <p style={{display: 'inline'}}>The amount for my purchase was charged twice. What can I do<p className='questionmark'>?</p></p>
              </AccordionSummary>
              <AccordionDetails>
                In case like this, please contact our relations team to get further assistance. Any payment
                debited twice should reflect to your account.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel20'} className='faqaccord' onChange={handleChange('panel20')} >
              <AccordionSummary expandIcon={expanded === 'panel20' ? <Close /> : <Add />}>
                Can I apply discount code to my purchase<p className='questionmark'>?</p>
              </AccordionSummary>
              <AccordionDetails>
                To maintain the exclusivity of our brand we have decided not to run any promotions or discounts
                on our product throughout our category.
              </AccordionDetails>
            </Accordion>
         </Accordion>
        </div>
      </div>
    </div>
  )
}

export default FaqPage