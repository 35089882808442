import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import "../css/ContactUs.css"
import { Call, Mail, Message, WhatsApp } from '@mui/icons-material'

function ContactUs() {
    return (
        <div className='contactus'>
            <h1>How can we assist you today<span className='questionmark' style={{marginLeft: '2px',fontWeight: '300'}}>?</span> </h1>
            <div className='contactus__holder'>
                <div className='contactus__boxs'>
                    <div className='contactus__callus glassy'>
                        <div className='top__box'>
                            <h2>CALL US</h2>
                            <span><label>Monday to Saturday:</label><label>10am - 7pm.</label></span>
                        </div>
                        <div className='cta'>
                            <button onClick={()=>window.location = "tel:+919867826848"}><Call style={{ height: '18px', marginRight: '5px' }} /> 91986 782 6848</button>
                        </div>
                    </div>
                    <div className='contactus__email glassy'>
                        <div className='top__box'>
                            <h2>EMAIL US</h2>
                            <span><label>Our client advisors will be delighted to answer your questions.</label></span>
                        </div>
                        <div className='cta'>
                            <button onClick={()=>window.location = "mailto:sales@2went6ex.com"}><Mail style={{ height: '18px', marginRight: '5px' }} /> Send a Email</button>
                        </div>
                    </div>
                    <div className='contactus__message glassy'>
                        <div className='top__box'>
                            <h2>MESSAGE US</h2>
                            <span><label>Our client advisors are at your service.</label></span>
                        </div>
                        <div className='cta'>
                            <button onClick={()=>window.location = "sms:9867826848;?&body=Thank you for reaching out to us!!!"}><Message style={{ height: '18px', marginRight: '5px' }} /> Apple Messages</button>
                            <button onClick={()=>window.location = "https://api.whatsapp.com/send?phone=9867826848"}><WhatsApp style={{ height: '18px', marginRight: '5px' }} /> Whatsapp</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bookanappointment'>
                <p>Contact our client services advisors to receive personalised support on product related inquiries, tailored recommendations on gifts, account management and more.</p>
                <div className='cta'>
                    <button><Mail style={{ height: '18px', marginRight: '5px' }} />Book an Appointment</button>
                </div>
            </div>
        </div>
    )
}

export default ContactUs