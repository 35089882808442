import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import FaqPage from './pages/FAQPage';
import ClientAdvisorPage from './pages/ClientAdvisorPage'
import BookAppointmentPage from './pages/BookAppointmentPage'
import ArtOfGiftingPage from './pages/ArtOfGiftingPage'
import NoMatch from './pages/NoMatch';
import AboutUsPage from './pages/AboutUsPage';
import CareersPage from './pages/CareersPage';
import SustainabilityPage from './pages/SustainabilityPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import LOGO from "./assets/TYPOL.png";
import NUMONIC from "./assets/numonic.png";
import Collections from './pages/Collections';
import ProductPage from './pages/ProductPage';
import Cart from './pages/Cart';
import { useEffect, useState } from 'react';
import client from './utils/shopify';
import Profile from './pages/Profile';
import Register from './pages/Register';
import ContactUs from './pages/ContactUs';
import InsideTheBox from './pages/InsideTheBox';
import ScrollToTop from './components/Scroll-To-Top';
import Login from './pages/Login';
import SearchPage from './pages/SearchPage';
import OurJourney from './pages/OurJourney';
import ArtPage from './pages/ArtPage';
import Women from './pages/Women';
import Men from './pages/Men.js';
import { logEvent } from 'firebase/analytics';
import analytics from './firebase.js';

function App() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [cartItemTotal, setCartItemTotal] = useState(0);
  const [on, setOn] = useState(false);

  setTimeout(() => {
    setLoadingPage(false)
    document.querySelector("meta[name='theme-color']").content = "#E9E6E1";
  }, 6000);

  const [cartId, setCartId] = useState('');

  const cartCreateQuery = `
  mutation MyMutation {
    cartCreate(input: {note: "2Went6ex Cart"}) {
      cart {
        id
      }
    }
  }
  `

  const getCartId = async () => {
    const { data, error } = await client.request(cartCreateQuery);
    const cartId = data['cartCreate']['cart']['id']
    setCartId(cartId)
    localStorage.setItem('cartId', cartId)
  }

  useEffect(() => {
    console.log(window.location.pathname)
    if (localStorage.getItem('cartId') != null) {
      console.log(localStorage.getItem('cartId'))
      setCartId(localStorage.getItem('cartId'))
    } else {
      getCartId()
    }
  }, [])

  useEffect(()=>{

    logEvent(analytics, 'website visited');
    console.log('website visited')
  },[])


  return (
    <Router>
      <div className="app">
        {window.location.pathname === '/' && loadingPage ? <></> : <Header cartItemTotal={cartItemTotal} setOn={setOn} on={on} />  }

        <div className='layout'>
          <Routes>
            <Route path='/' index element={<LandingPage loadingPage={loadingPage} setLoadingPage={setLoadingPage} />} />
            <Route path='/collection' element={<Navigate to={'/collections'} />} />
            <Route path='/collections' element={<Collections />} />
            <Route path='/products/:id' element={<ProductPage />} />
            <Route path='/cart' element={<Cart cartId={cartId} getCartId={getCartId} setCartItemTotal={setCartItemTotal} />} />
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/art' element={<ArtPage />} />
            <Route path='/artofgifting' element={<ArtOfGiftingPage />} />
            <Route path='/aboutus' element={<AboutUsPage />} />
            <Route path='/ourjourney' element={<OurJourney/>}/>
            <Route path='/bookanappointment' element={<BookAppointmentPage />} />
            <Route path='/contactus' element={<ContactUs />} />
            <Route path='/careers' element={<CareersPage />} />
            <Route path='/clientadvisor' element={<ClientAdvisorPage />} />
            <Route path='/sustainability' element={<SustainabilityPage />} />
            <Route path='/privacypolicy' element={<PrivacyPolicyPage />} />
            <Route path='/faq' element={<FaqPage />} />
            <Route path='/inside-the-box' element={<InsideTheBox />} />
            <Route path='/women' element={<Women />}/>
            <Route path='/men' element={<Men />}/>
            <Route path="*" element={<NoMatch />} /> 
          </Routes>
          
        </div>
        {window.location.pathname === '/' && loadingPage ? <></> : <Footer /> }
        <ScrollToTop />
      </div>
    </Router>
  );
}

export default App;


/** */

/**      <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' ,minHeight: '100vh'}}>
<img src={NUMONIC} width={100} />
<img src={LOGO} width={100} />
<h1 style={{fontSize: '25px'}}>SITE UNDER MAINTAINCE</h1>
<img style={{margin: '20px'}} src='https://cdnl.iconscout.com/lottie/premium/thumb/website-under-maintenance-5690953-4747761.gif' />
</div> */

/**<Route path='/collection' element={<Navigate to={'/collections'} />} />
            <Route path='/collections' element={<Collections />} />
            <Route path='/products/:id' element={<ProductPage />} />
            <Route path='/cart' element={<Cart cartId={cartId} setCartItemTotal={setCartItemTotal} />} />
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/art' element={<ArtPage />} />
            <Route path='/artofgifting' element={<ArtOfGiftingPage />} />
            <Route path='/aboutus' element={<AboutUsPage />} />
            <Route path='/ourjourney' element={<OurJourney/>}/>
            <Route path='/bookanappointment' element={<BookAppointmentPage />} />
            <Route path='/contactus' element={<ContactUs />} />
            <Route path='/careers' element={<CareersPage />} />
            <Route path='/clientadvisor' element={<ClientAdvisorPage />} />
            <Route path='/sustainability' element={<SustainabilityPage />} />
            <Route path='/privacypolicy' element={<PrivacyPolicyPage />} />
            <Route path='/faq' element={<FaqPage />} />
            <Route path='/inside-the-box' element={<InsideTheBox />} />
            <Route path='/women' element={<Women />}/>
            <Route path='/men' element={<Men />}/>
            <Route path="*" element={<NoMatch />} /> */