import React, { useEffect, useState } from 'react'
import client from '../utils/shopify'
import { Link } from 'react-router-dom'
import LocomotiveScroll from 'locomotive-scroll'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import '../css/PAL.css'

function PAL() {
    const [products, setProducts] = useState([])
    const collectionQuery = `
      query CollectionQuery {
              products(first: 10) {
                nodes {
                  id
                  title
                  handle
                  images(first: 2) {
                    nodes {
                      src
                      id
                    }
                  }
                  priceRange {
                    minVariantPrice {
                      amount
                    }
                  }
                }
              }
      }`
    const productsDomNodes = [];

    const fetchCollection = async () => {
        const { data, errors } = await client.request(collectionQuery)
        console.log(data['products']['nodes'])
        setProducts(data['products']['nodes'])
    }

    useEffect(function () {
        fetchCollection()


    }, [])
    return (
        <div className='pal' >
            <h1 style={{fontSize: '30px'}}>People also like</h1>
            <div className='pal__detail' >
                {
                    products.map((product) => (
                        <Link to={`/products/${product['handle']}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', color: 'black' }}>

                            <div className='pal__item' >
                                <img loading='lazy' decoding='async' src={product['images']['nodes'][0]['src']} onMouseEnter={e => (e.currentTarget.src = product['images']['nodes'][1]['src'])} onMouseOut={e => (e.currentTarget.src = product['images']['nodes'][0]['src'])} />

                                <label>{product['title']}</label>

                                
                            </div>
                        </Link>
                    ))

                }
            </div>
        </div>
    )
}

export default PAL


/**
                                <p>₹{product['priceRange']['minVariantPrice']['amount']}</p> */