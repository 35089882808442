import React, { useEffect, useRef } from 'react';
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import "../css/OurJourney.css";

// Combined array of videos and images (mixed order)
const mediaItems = [
  { type: 'image', src: 'https://cdn.shopify.com/s/files/1/0643/2787/6757/files/OUR_JORNEY_CONTENT.jpg?v=1728395158' },
  { type: 'video', src: 'https://cdn.shopify.com/videos/c/o/v/2f27cf672f61445781521497bfd9ce16.mov' },
  { type: 'image', src: 'https://cdn.shopify.com/s/files/1/0643/2787/6757/files/3.heic?v=1728307329' },
  { type: 'video', src: 'https://cdn.shopify.com/videos/c/o/v/3999217283084ce1a77a38c3d58c4696.mov' },
  { type: 'image', src: 'https://cdn.shopify.com/s/files/1/0643/2787/6757/files/4.heic?v=1728307330' },
  { type: 'video', src: 'https://cdn.shopify.com/videos/c/o/v/edc88b9e87a34843b2aa4744a910f8d3.mov' },
  { type: 'image', src: 'https://cdn.shopify.com/s/files/1/0643/2787/6757/files/6.jpg?v=1728307329' },
  { type: 'video', src: 'https://cdn.shopify.com/videos/c/o/v/1d368bc0a5124bc3b60525139632cca3.mp4' },
  { type: 'image', src: 'https://cdn.shopify.com/s/files/1/0643/2787/6757/files/8.heic?v=1728307329' },
];

function OurJourney() {
  const glideRef = useRef(null);

  useEffect(() => {
    const glide = new Glide(glideRef.current, {
      type: 'carousel',
      perView: 1,
      hoverpause: true,
      animationDuration: 800, // Transition duration in milliseconds
      animationTimingFunc: 'ease-in-out', // Easing function
    });
    
    glide.mount();

    return () => glide.destroy(); // Clean up on unmount
  }, []);

  return (
    <div className="glide" ref={glideRef}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {/* Render mixed media slides */}
          {mediaItems.map((item, index) => (
            <li className="glide__slide" key={index}>
              {item.type === 'video' ? (
                <video
                  muted
                  autoPlay
                  loop
                  playsInline
                  src={item.src}
                  className="media-video"
                />
              ) : (
                <img
                  src={item.src}
                  alt={`Slide ${index + 1}`}
                  className="media-image"
                />
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Arrows for navigation */}
      <div className="glide__arrows" data-glide-el="controls">
        <button className="glide__arrow glide__arrow--left" data-glide-dir="<">←</button>
        <button className="glide__arrow glide__arrow--right" data-glide-dir=">">→</button>
      </div>

      <h1 className='oj'>Our Journey</h1>
    </div>
  );
}

export default OurJourney;
