import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ".././css/Header.css";
import TYPOL from "../assets/pathnames/typo.png";
import ETHICS from "../assets/ETHICS.png";
import CAREERS from "../assets/CAREERS.png";
import MEN from "../assets/pathnames/men.png"
import WOMEN from "../assets/pathnames/women.png"
import MEMBERS from "../assets/pathnames/members.png"
import COLLECTIONS from "../assets/pathnames/collections.png";
import HELP from "../assets/pathnames/help.png"
import CARTL from "../assets/pathnames/cart.png"
import PRIVACYPOLICY from "../assets/pathnames/privacy.png"
import ART from "../assets/pathnames/art1.png"
import FAQ from "../assets/pathnames/faqs.png"
import BAG from "../assets/bag.svg";
import { Link } from "react-router-dom";
import Drawer from "react-bottom-drawer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccordionDetails, Backdrop, Modal } from "@mui/material";
import { Add, Call, Close, } from "@mui/icons-material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import SearchComponent from "./SearchComponent";

function Header({ cartItemTotal, on, setOn }) {
  const location = useLocation();
  const [showCollection, setShowCollection] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showShop, setShowShop] = useState(false);
  const [showExplore, setShowExplore] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);


  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  const handleHeaderOpen = () => setShowHeader(true);
  const handleHeaderClose = () => { setShowHeader(false); setExpanded('') };
  const handleCollectionOpen = () => setShowCollection(true);
  const handleCollectionClose = () => setShowCollection(false);
  const handleShopOpen = () => setShowShop(true);
  const handleShopClose = () => setShowShop(false);
  const handleExploreOpen = () => setShowExplore(true);
  const handleExploreClose = () => setShowExplore(false);

  const [isScrolled, setIsScrolled] = useState(false);
  const [y, setY] = useState(0);


  const handleNavigation = (e) => {
    const window = e.currentTarget;
    if (window.scrollY > 80) {
      setIsScrolled(true);
    } else if (window.scrollY < 80) {
      setIsScrolled(false);
    }
    setY(window.scrollY);
  };

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", (e) => handleNavigation(e));
  }, [window.scrollY]);

  useEffect(() => {
    setShowCollection(false)
    setShowExplore(false)
    setShowShop(false)
  }, [location])

  return (
    <header>
      <div
        className="header__panel"
        style={{ background: isScrolled ? "" : "" }}
      >
        <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center' }}>
          <div className="logo">
            <Link to="/">
              {location.pathname !== "/" ? (
                <div style={{ position: 'absolute', display: "flex", left: "10px", justifyContent: "end", alignItems: 'baseline', color: 'black' }}>
                  <img height={width >= 1000 ? 40 : 32} src={(() => {
                    switch (location.pathname) {
                      case "/":
                        return TYPOL;
                      case "/collections":
                        return COLLECTIONS;
                      case "/men":
                        return MEN;
                      case "/women":
                        return WOMEN;
                      case "/contactus":
                        return HELP;
                      case "/register":
                        return MEMBERS;
                      case "/login":
                        return MEMBERS;
                      case "/faq":
                        return FAQ;
                      case "/privacypolicy":
                        return PRIVACYPOLICY;
                      case "/art":
                        return ART;
                      case "/cart":
                        return CARTL;
                      default:
                        return TYPOL;
                    }
                  })()} />

                </div>
              ) : (
                <img
                  alt="logo"
                  className={`logo__image ${isScrolled ? "logo__scroll" : ""}`}
                  src={TYPOL}
                />
              )}
            </Link>
          </div>
        </div>
        {
          width > 1000
            ? <><div className="floatbar__holder">
              <div className="floatbar">
                <button onMouseEnter={handleCollectionOpen} onMouseLeave={handleCollectionClose} className="navbar-toggle">COLLECTIONS</button>
                <button onMouseEnter={handleShopOpen} onMouseLeave={handleShopClose} className="navbar-toggle">SHOP</button>
                <button onMouseEnter={handleExploreOpen} onMouseLeave={handleExploreClose} className="navbar-toggle">EXPLORE</button>
              </div>
            </div>
              <div className="header__right">
                <div>
                  <SearchComponent on={on} setOn={setOn} />
                </div>
                <Link to={'/profile'} style={{ textDecoration: 'none', color: 'black', marginLeft: '10px', textDecorationLine: 'underline' }}>
                  My26
                </Link>
                <Link to={'/cart'} style={{ textDecoration: 'none', color: 'black', marginLeft: '10px', textDecorationLine: 'underline' }}>
                  Cart / {cartItemTotal}
                </Link>

              </div>
            </>
            : <>
              <div >
                <SearchComponent on={on} setOn={setOn} />
              </div>

              <div className="mobile__drawer">
                <img src="https://www.svgrepo.com/show/506800/burger-menu.svg" onClick={() => handleHeaderOpen()} width={40} />
              </div>
            </>

        }
      </div>
      <div className="modal__container">
        {showCollection ? <div className="modal__container" onMouseEnter={handleCollectionOpen} onMouseLeave={handleCollectionClose}>
          <div className="nav__links">
            <Link to={'/collections'}>SS 25 / No.26</Link>
          </div>
        </div>
          : <></>}
        {showShop ? <div className="modal__container" onMouseEnter={handleShopOpen} onMouseLeave={handleShopClose}>
          <div className="nav__links">
            <Link to={'/women'} >WOMEN </Link>
            <Link to={'/men'} >MEN </Link>
            <Link to={'/art'} >ART </Link>
          </div>
        </div> : <></>}
        {
          showExplore
            ? <div className="modal__container" onMouseEnter={handleExploreOpen} onMouseLeave={handleExploreClose}>
              <div className="nav__links">
                <Link to={'/ourjourney'}>OUR JOURNEY</Link>
                {/*<Link to={'/inside-the-box'}>INSIDE THE BOX</Link>*/}
              </div>
            </div>
            : <></>
        }
      </div>
      <Drawer


        duration={750}

        hideScrollbars={true}
        onClose={handleHeaderClose}
        isVisible={showHeader}
      >
        <div style={{ minHeight: '100vh' }}>
          <div onClick={() => { handleHeaderClose(); }} style={{ position: 'absolute', top: '20px', right: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50px', width: '50px', height: '50px', background: 'black', color: 'white' }}><h1 style={{ fontFamily: 'BDB', fontSize: '20px' }}>X</h1></div>
          </div>
          <div style={{ position: 'absolute', top: '30px', left: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1>MENU</h1>
          </div>
          <div style={{ position: 'absolute', top: '100px', left: '10px' }}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ background: 'transparent', boxShadow: 'none', width: '100%' }}>
              <AccordionSummary
                expandIcon={expanded === 'panel1' ? <Close /> : <KeyboardArrowDownIcon />}
              >
                <h2>COLLECTIONS</h2>
              </AccordionSummary>
              <AccordionDetails>
                <div className="menuHolder">
                  <Link onClick={() => handleHeaderClose()} to={'/collections'}>SS 25 / No.26<sup>17</sup></Link>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ background: 'transparent', boxShadow: 'none', width: '100%' }}>
              <AccordionSummary
                expandIcon={expanded === 'panel2' ? <Close /> : <KeyboardArrowDownIcon />}
              >
                <h2>SHOP</h2>
              </AccordionSummary>
              <AccordionDetails>
                <div className="menuHolder" >
                  <Link onClick={() => handleHeaderClose()} to={'/women'} >WOMEN <sup>10</sup></Link>
                  <Link onClick={() => handleHeaderClose()} to={'/men'} >MEN <sup>10</sup></Link>
                  <Link onClick={() => handleHeaderClose()} to={'/art'} >ART<sup></sup></Link>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ background: 'transparent', boxShadow: 'none', width: '100%' }}>
              <AccordionSummary
                expandIcon={expanded === 'panel3' ? <Close /> : <KeyboardArrowDownIcon />}
              >
                <h2>EXPLORE</h2>
              </AccordionSummary>
              <AccordionDetails>
                <div className="menuHolder">

                  <Link onClick={() => handleHeaderClose()} to={'/ourjourney'}>OUR JOURNEY <sup></sup></Link>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div style={{ position: 'absolute', bottom: '20px', left: '30px' }}>
            <Link onClick={() => handleHeaderClose()} style={{ textDecoration: 'none', color: 'black' }} to='/contactus'>Contact us<sup><Call style={{ width: '15px', position: 'absolute', marginLeft: '2px', bottom: '8px' }} /></sup></Link>
          </div>
          <div style={{ position: 'absolute', bottom: '20px', right: '30px' }}>
            <Link style={{ textDecoration: 'none', color: 'black' }} onClick={() => handleHeaderClose()} to={'/profile'}>
              My26
            </Link>
            <Link onClick={() => handleHeaderClose()} to={'/cart'} style={{ textDecoration: 'none', color: 'black', marginLeft: '20px' }}>
              Cart / {cartItemTotal}
            </Link>
          </div>
        </div>

      </Drawer>
    </header>
  );
}

export default Header;


/*<Link to={'/accessories'} >ACCESSORIES </Link>

/** <Link to={'/sustainability'}>SUSTAINABILITY</Link>*/


/**        <div className="floatbar__holder">
          <div className="floatbar">
            <button onClick={handleCollectionOpen} className="navbar-toggle">COLLECTIONS</button>
            <button onClick={handleShopOpen} className="navbar-toggle">SHOP</button>
            <button onClick={handleExploreOpen} className="navbar-toggle">EXPLORE</button>

          </div>
        </div>

        <div className="header__right">

          <Link to={'/profile'}>
            My26
          </Link>
          <Link to={'/cart'} style={{ textDecoration: 'none', color: 'black', marginLeft: '10px', textDecorationLine: 'underline' }}>
            Cart / {cartItemTotal}
          </Link>

        </div> */





//<img alt='logo' src={TYPOL} width={250}/>

/**  <Link to='/'>MY26</Link>
                    <div className='nav__cart'>
                        <span>10</span>
                        <img src={BAG} alt='BAG' />
                    </div> */

/**                {location.pathname === "/sustainability" ? (
                  <img src={ETHICS} height={34} />
                ) : (
                  <></>
                )} */

//E9E6E1
/** ${location.pathname === "/" ? (isScrolled ? "" : "noshow") : ""
                  } */


/**<div className="navbar-brand">
<button
className={`navbar-toggle ${isOpen ? "active" : ""}`}
onClick={toggleNavbar}
>
<span></span>
<span></span>
<span></span>
</button>
</div> */


/**<div>
            Search
          </div> */


/**      <div className="modal__container">
<Modal
open={showCollection}
onClose={handleCollectionClose}
closeAfterTransition
slots={{ backdrop: Backdrop }}
slotProps={{
  backdrop: {
    sx: {
      backgroundColor: 'rgba(255,255,255,0.3)',
    }
  }
}}
>
<div className="modal__container">
  <div className="nav__links">
    <Link to={'/collections'}>SS24 </Link>
  </div>
</div>
</Modal>
<Modal
open={showShop}
onClose={handleShopClose}
closeAfterTransition
slots={{ backdrop: Backdrop }}
slotProps={{
  backdrop: {
    sx: {
      backgroundColor: 'rgba(255,255,255,0.3)',
    }
  }
}}
>
<div className="modal__container">
  <div className="nav__links">
    <Link to={'/men'} >MEN </Link>
    <Link to={'/women'} >WOMEN </Link>
    <Link to={'/accessories'} >ACCESSORIES </Link>
    <Link to={'/art'} >ART </Link>
  </div>
</div>
</Modal>
<Modal

open={showExplore}
onClose={handleExploreClose}
closeAfterTransition
slots={{ backdrop: Backdrop }}
slotProps={{
  backdrop: {
    sx: {
      backgroundColor: 'rgba(255,255,255,0.3)',
    }
  }
}}
><div className="modal__container">
  <div className="nav__links">
    <Link to={'/ourstory'}>OUR JOURNEY</Link>
    <Link to={'/sustainability'}>SUSTAINABILITY</Link>
    <Link to={'/inside-the-box'}>INSIDE THE BOX</Link>
  </div>
</div>
</Modal>
</div> */