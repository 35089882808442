import React from "react";
import "../css/Pages.css";
import LimitedPro from "../assets/Limited.jpeg";
import Donation from "../assets/Donate.jpeg";
import Renew from "../assets/Electricity.jpeg";
import video from "../assets/susvid3.mp4"


function SustainabilityPage() {
  return (
    <div className="sustainability__page">
      <div className="film">
        <video
          src={video}
          muted
          autoPlay
          loop
        />
        <div className="overlay"></div>
       
      </div>
      <div className="sustainability__section">
        <div className="sustainability__content">
          <div className="sustainability__para">
            <p>
              Sustainability is a founding element of 2went6ex identity and is
              deeply rooted in its ethics as well as in the Company’s strategy.
              The brand’s sustainability commitment is meant to contribute to
              “People”, “Planet” and “Culture”.
            </p>
            <p>
              Experimentation creates new unforeseen obstacles that challenge
              our methods and drives continual evolution. Our goal is to
              innovate without harming humans or our environment and create a
              responsible fashion system.
            </p>
          </div>
          <div className="sustainability__container">
            <div className="sustainability__card card__1">
              <div>
                <span>
                  At 2went6ex we believe Limited production allows for a focus
                  on quality rather than quantity. Items are typically crafted
                  with more attention to detail and higher-quality materials,
                  resulting in longer-lasting products and lesser harm on the
                  environment.
                </span>
              </div>
              <img src={LimitedPro} id="limitpro" />
            </div>
            <div className="sustainability__card">
              <div>
                <span>
                  At 2went6ex we are exploring the possibility of sourcing
                  electricity from <b>renewable energy</b> providers. This may
                  include solar, wind, hydroelectric, or other renewable
                  sources. Using clean energy can significantly reduce our
                  carbon footprint.
                </span>
              </div>
              <img src={Renew} id="renew" />
            </div>
            <div className="sustainability__card">
              <div>
                <span>
                  At 2went6ex we believe in giving back to the society for the
                  impacts we do on the environment. For every garment sold we
                  donate 5% to Water Aid India. Collective efforts can have a
                  more significant impact on addressing social and environmental
                  issues.
                </span>
              </div>
              <img src={Donation} id="donate" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SustainabilityPage;

/**    <div>
      <p>Sustainability is a founding element of 2went6ex identity and is deeply rooted in its ethics as well as in the Company’s strategy. The brand’s sustainability commitment is meant to contribute to “People”, “Planet” and “Culture”.Experimentation creates new unforeseen obstacles that challenge our methods and drives continual evolution. Our goal is to innovate without harming humans or our environment and create a responsible fashion system.</p>
    </div> */


    /*** <span className="title">Planet over Profit</span> */