import React from "react";
import ".././css/Footer.css";
import { Link } from "react-router-dom";
import logogif from "../assets/logoanim3.gif"
import NUMONIC from "../assets/numonic.png";

function Footer() {
  const socials = {
    whatsapp: "https://wa.me/message/KZDO4OZ6C44RK1",
    instagram: "instagram://user?username=2went6ex",
    spotify:
      "https://open.spotify.com/user/j2rq24953b24oqqyn3v40mpma?si=hVHsLZofSDytjBmuEYAgaQ",
    pinterest: "https://pin.it/34EV5Gy",
  };
  return (
    <footer>
      <div className="footer__panel">
      <div className="footer__logo">
        <img alt="logo" width={300} src={logogif} />
      </div>
        <div className="footer__card">
          <ul>
            <label>NEED HELP <label className="questionmark" style={{fontWeight: '400', marginLeft: '0px'}}>?</label></label>
            <Link to={"/contactus"}>Contact Us</Link>
            <Link to={"/faq"}>FAQs</Link>
            {
              window.innerWidth > 600 ? <> <Link >&nbsp;</Link>
              <Link> &nbsp;</Link></>
              : <></>
            }
          </ul>
        </div>
        <div className="footer__card">
          <ul>
            <label>OUR COMPANY</label>
            <Link to={"/ourjourney"}>Our Journey</Link>
            <Link to={"/privacypolicy"}>Privacy Policy</Link>
            <> <Link >&nbsp;</Link> </>
            <> <Link >&nbsp;</Link> </>
          </ul>
        </div>
        <div className="footer__card">
          <ul>
            <label>CONNECT</label>
            <a href={socials.whatsapp} target="_blank" rel="noreferrer">
              Whatsapp
            </a>
            <a href={window.innerWidth > 600 ? "https://www.instagram.com/2went6ex/" : socials.instagram} target="_blank" rel="noreferrer">
              Instagram
            </a>
            <a href={socials.spotify} target="_blank" rel="noreferrer">
              Spotify
            </a>
            <a href={socials.pinterest} target="_blank" rel="noreferrer">
              Pinterest
            </a>
          </ul>
        </div>
       
      </div>
      
    </footer>
  );
}

export default Footer;


/*<Link to={"/careers"}>Careers</Link>
/*<Link to={"/sustainability"}>Sustainability</Link>

/**<div className="footer__logo">
        <img alt="logo" src={LOGO} />
      </div> */

/***        <div className="footer__card">
          <img width={100} src={NUMONIC} />
        </div> */

/**<div className="footer__contact">
        <a className="footer__phone" target="_blank" href="tel:+919867826848">
          Call Us +919867826848
        </a>
        <a
          className="footer__address"
          target="_blank"
          href="https://maps.app.goo.gl/oicUu9md69Xgg8on8?g_st=iwb"
        >
          2409 Solus building, Hiranandani estate, Thane (W) - 400607
        </a>
      </div> */


      /** <div className="footer__card last">
          <ul>
            <label>Experience</label>
            <Link>Brand Story</Link>
            <Link>Innovation</Link>
            <Link>AI Experience</Link>
            <Link>Character</Link>
          </ul>
        </div> */

        /**            <Link >&nbsp;</Link>
            <Link> &nbsp;</Link> */