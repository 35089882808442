import React from "react";
import "../css/CareersPage.css";
import JOINUS from "../assets/JOINUS.jpg";


function CareersPage() {
  return (
        <div className="careerspage">
          <div className="careerspage__content">
            <div className="joinus__holder">
              <img src={JOINUS} />
            </div>

            <div className="animation">
              <h1>EMPOWERING AMBITION</h1>
            </div>
            <div className="careerspage__text">
              <h1>
                2WENT6EX community is always searching for talents like
                you.Explore the career opportunities waiting for you at
                2WENT6EX. Whether you are an experienced professional or just
                starting your career journey, we have positions that could be
                the perfect fit for you.
              </h1>
            </div>
            <div className="box__holder">
              <h1 className="careerspage__box">
                There is a power in people who are ambitious and passionate.
              </h1>
              <h1 className="careerspage__box">
                We want people to feel part of a positive, open and fair
                workspace. Being with others, having different ideas and way of
                expressing themselves is at the core of beliefs. It is the best
                way to respond to some of the biggest issues in our society.
              </h1>
            </div>
            <div className="emailusat">
              <h3>EMAIL US AT <a href="mailto:info@2went6ex.com">info@2went6ex.com</a></h3>
              <h1>DON'T FUCK UP</h1>
            </div>
            <div className="careerspage__tagline">
              <h1>WELCOME TO</h1>
              <h1> 2WENT6EX</h1>
            </div>

            <h1 className="TMOUS">This is what it means to be one of us</h1>
          </div>
        </div>
  );
}

export default CareersPage;
