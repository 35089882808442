import React from "react";
import "../css/PrivacyPolicy.css"
import LockLogo from "../assets/lock.png";
import { motion } from "framer-motion"
import { useRef, useEffect, useState } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import LocomotiveScroll from 'locomotive-scroll';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);



function PrivacyPolicyPage() {

  const height = window.innerHeight;

  useGSAP(() => {



    const tl = gsap.timeline()

    tl.from('.privacy__scrollformore', {
      y: 5,
      yoyo: true,
      repeat: -1,
      defaults: { // <-- important
        duration: 2,
        ease: "none"
      }
    })
    gsap.from(".privacyblock", {
      opacity: 0,
      delay: 0.5,
      y: 100,
      stagger: 1,
      ease: 'power1.inOut',
      scrollTrigger: {
        scrub: true,
        /*markers: true,*/
        trigger: '.privacypolicy',
        start: 'top 50%',
        end: height >= 1500 ? 'bottom 80%' : 'bottom 50%'
      }
    })

  })

  return (
    <div className="privacypolicy">
      {
        height >= 900
          ? <div className="privacy__scrollformore">
            <h5>Scroll</h5>
            <h5> For </h5>
            <h5>More</h5>
          </div>
          : <></>
      }

      <div className="privacypolicy__container" >
        <div className="privacypolicy__content" >
          <p >
            <div className="privacyblock" >
              <h1 data-scroll>GENERAL TERMS AND CONDITIONS OF USE</h1>
              <h5 data-scroll>
                Welcome to the website www.2WENT6EX.com (hereinafter the
                “Website”).{" "}
              </h5>
              <h5>
                The use of the Website is governed by the following terms and
                conditions of use (hereinafter the “Terms and Conditions of Use”).
                By entering this Website, you unconditionally agree to comh5ly with
                and be bound by the following Terms and Conditions of Use.{" "}
              </h5>
              <h5>
                This Website is operated by 2WENT6EX, operating holding company
                of…., which reserves the right to modify these Terms and
                Conditions of Use at its sole discretion, at any time and without
                notice. The use of the Website after such modifications implies
                full acceptance of the Terms and Conditions of Use in their
                amended version and full acceptance of their mandatory nature.
              </h5>
            </div>
            <div className="privacyblock">
              <h1>INTELLECTUAL PROPERTY RIGHTS</h1>
              <h2>
                This Website is owned and registered by companies of the 2WENT6EX.{" "}
              </h2>
              <h5>
                All trademarks and logos, whether registered or not, displayed on
                the Website, including but not limited to the 2WENT6EX trademarks,
                as well as the other distinctive marks connected with the 2WENT6EX
                and reproduced on the Website, are and will remain the exclusive
                property of the respective owners. These trademarks and logos may
                not be used in connection with any product or service that does
                not originate with 2WENT6EX in any manner that is likely to cause
                confusion among users, or in any manner that disparages or
                discredits the 2WENT6EX name and brand. You acknowledge and agree
                that the Website and all content included therein, including, but
                not limited to, all text, information, data, images,
                distinguishing marks, icons, photographs, illustrations,
                multimedia content (graphic, audio and video), charts, indices,
                reports, software, HTML codes and screens contained therein and
                the like (hereinafter collectively “Content”),is owned by or
                licensed to 2WENT6EX and is subject to protection by international
                intellectual property laws, including, but not limited to, rights
                in the nature of patent rights, copyrights, trade secrets, trade
                names, trademarks, service marks, moral rights, know-how and any
                other similar rights recognized under laws or international
                conventions in any country or jurisdiction in the world. Subject
                to these Terms and Conditions of Use, and except as otherwise
                indicated on the Website, you may access, download, copy, store,
                manipulate, reformat, print or display any Content to which you
                have authorized access solely for your own personal use. You may
                not otherwise download, copy, store, manipulate, reformat, print,
                display, publish, transmit, distribute, create a derivative work
                from, resell or make any other use of, the Website, or any Content
                contained therein. No right, title and/or interest deriving from
                or pertaining to the Content, material or software of the Website
                may be deemed assigned to or acquired by you as a result of your
                use of the Website. You agree to accept and abide by all copyright
                or trademark notices and other notices contained on the Website
                and in the Content. All rights are reserved in all countries
                worldwide.</h5>
            </div>
            
            <div className="privacyblock">
              <h1>DISCLAIMER</h1>
              <h2>
                The Website and its Content are provided on an “as is” and “as
                available” basis, without any warranties of any kind, either
                express or implies statutory or otherwise. No written information
                given by 2WENT6EX through this Website or in relation to its
                Content shall create any warranty.
              </h2>
              <h2>
                All materials contained in the Website are displayed for
                informational and illustrative purposes only.
              </h2>
              <h5>
                2WENT6EX makes an effort to ensure the accuracy and timeliness of
                the information included on this Website but cannot absolutely
                guarantee that this information is complete, accurate and
                constantly up to date and that the Website does not contain any
                imperfections or that these imperfections will be corrected.
                2WENT6EX does not undertake any obligation to respond to, nor
                accept any liability arising from, any inaccuracies or omissions
                in this Website, other than for fraudulent misrepresentation.
              </h5>
              <h5>
                No information contained in this Website constitutes or can be
                deemed to constitute an invitation or inducement to invest or
                otherwise deal in the securities of 2WENT6EX. The use of the data
                and the information contained in this Website for personal
                investment decision-making purposes is made at the user’s own
                risk. 2WENT6EX recommends that any decisions on investments in
                securities of 2WENT6EX be made after reading the information
                contained in documents filed with or furnished to the relevant
                regulatory authorities. In no event shall 2WENT6EX, or any of the
                2WENT6EX’s company, be liable for any direct, indirect,
                incidental, ancillary, financial or non-financial damage
                whatsoever (including, but not limited to, damages for loss of
                profits or revenue, data or programming, even if 2WENT6EX has been
                advised of the possibility of such damages) arising out of: (a)
                your use of the Website, including, but not limited to, any loss
                or damage caused by any delays, inaccuracies, errors or omissions
                in any information accessed on or through the Website, (b) any
                transaction entered into through or from the Website, (c) your
                inability to use the Website for whatever reason, including, but
                not limited to, communications failure or any other failure with
                transmission or delivery of any information accessed on or though
                the Website, (d) the deletion, correction, damage, loss or failure
                to store any information concerning or transmitted on the Website,
                (e) the use of any products or services obtained on or through the
                Website, (f) unauthorized access to the Website and unauthorized
                alteration to transmissions or data, (g) statements or conduct of
                any third party on the Website, or (h) any other matter relating
                to the Website. Therefore, by using this Website, you undertake to
                bear all risks connected with such use, holding 2WENT6EX harmless
                from any damage deriving or caused by use of this Website or
                connected with it. However, 2WENT6EX reserves the right to modify
                or discontinue the Website (or any part thereof) at any time,
                temporarily or permanently, with or without notice. You agree that
                2WENT6EX shall not be liable to you or any third party for any
                modification, suspension or discontinuance of the Website.
              </h5>
            </div>
            <div className="privacyblock">
              <h1>COOKIES</h1>
              <h5>
                Cookies are used in the Website to ensure its efficient
                functioning and to improve our service. Cookies are small text
                files sent to the user’s computer (usually to the user’s browser)
                by visited websites; they are stored in the user’s computer or
                device to be then re-transmitted to the websites on the user’s
                subsequent visits to those websites. 2WENT6EX uses temporary
                session cookies that are automatically eliminated any time the
                Website browsing session ends or when the browser is closed and
                permanent cookies that are stored on the user’s device until their
                expiration or deletion by the user. Cookies cannot be used to
                retrieve other data from the hard disk, transmit computer viruses
                or identify and use e-mail addresses or passwords. In the Website
                cookies are used to guarantee regular navigation and use of the
                Website and to collect information in an anonymous form about how
                users use the Website. This information allows us to make constant
                updates to improve the use of the Website by users.
              </h5>
              <h5>
                Most browsers are initially set to accept cookies. You can at any
                time reset your browser to reject all cookies or to notify you
                when a new cookie is being sent. However, rejecting cookies may
                prevent you from using certain functions of the Website.
              </h5>
              <h5>
                In order to know which cookies are used in the Site, how to
                disable them, or for any further information, see the cookie
                policy.
              </h5>
            </div>
            <div className="privacyblock">
              <h1>PRIVACY</h1>
              <h5>
                In general, users will not be asked to provide any personal
                information in order to access and browse the Website. However,
                there may be times when you may be requested to, knowingly and
                willingly, provide some personal information, so that you may
                access and use particular services or features of the Website. The
                relevant Privacy Policy is available in the Website section where
                personal data are collected.{" "}
              </h5>
              <h5>
                For the purpose of using these Website sections, you undertake to
                read the relevant privacy policy expressing, when requested, your
                consent to your personal data processing and you agree to provide
                true, accurate, current and complete information.
              </h5>
              <h5>
                Furthermore, sending e-mail messages through the Website and to
                the addresses mentioned on the Website, which is done on the basis
                of a freely chosen, explicit, and voluntary option, entails
                acquisition by 2WENT6EX of the sender's address, which is
                necessary in order to reply to any request, as well as of such
                additional Personal Data as is contained in the message(s).
              </h5></div>
              <div className="privacyblock">
              <h1>LINKS TO OTHER WEB SITES</h1>
              <h5>
                The Website may contain links to other websites owned by the
                2WENT6EX and/or third parties. These websites may contain and be
                governed by their own terms and conditions of use or no terms and
                conditions at all. 2WENT6EX provides these links solely for the
                convenience of its users. These websites may use our logo and/or
                style as a result of a co-branding agreement and may send their
                own cookies to you, collect information and use same in a way
                inconsistent with these Terms and Conditions of Use. 2WENT6EX
                cannot be held responsible for any damage or losses, true or
                alleged, deriving from the use or from having trusted the content,
                or goods and services available on those websites or external
                sources.
              </h5>
            </div>
            <div className="privacyblock">
              <h1>SECURITY</h1>
              <h5>
                Transmission of data over the Internet is never guaranteed to be
                completely secure. It is possible that third parties not under the
                control of 2WENT6EX may be able to access or intercept
                transmissions or private communications without 2WENT6EX's
                permission or knowledge. Despite our efforts 2WENT6EX cannot
                ensure or warrant the security of any information you transmit to
                us. Such transmissions are done at your own risk and PRADA cannot
                be held liable for any breach of security which is not directly
                attributable to 2WENT6EX’s negligent behavior. ACCEPTANCE OF TERMS
                AND CONDITIONS OF USE By accessing this Website, you represent and
                warrant that (a) you accept these Terms and Conditions of Use; (b)
                your use of the Website will comply with these Terms and
                Conditions of Use. You agree to indemnify and hold harmless
                2WENT6EX from all damages, costs, liabilities, suits, judgments,
                penalties, expenses, obligations, losses, claims, actions, costs
                and expenses (including, but not limited to, attorneys’ fees and
                expenses), relating to or arising out of any breach of these Terms
                and Conditions of Use or any use of the Website by you.
              </h5>
            </div>
            <div className="privacyblock">
              <h1>GOVERNING LAW: JURISDICTION</h1>
              <h5>
                These Terms and Conditions of Use shall be governed by and
                construed in accordance with the Indian laws and any action
                arising out or relating to these Terms and Conditions of Use shall
                be subject to the exclusive jurisdiction of the Courts of Mumbai,
                India
              </h5>
              <h5>
                It remains understood that, with respect to the proper
                interpretation of these Terms and Conditions of Use, in case of
                any discrepancy between the Italian language original text and any
                other language translation, the Italian original text shall be
                deemed the binding version.
              </h5>
            </div>
           <div className="privacyblock"></div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;


/** <div className="privacyblock">
              <ul>
                <li>
                  <b>Enterprise Core:</b>
                  <span>6GPYM-VHN83-PHDM2-Q9T2R-KBV83</span>
                </li>
                <li>
                  <b>Developer:</b>
                  <span>22222-00000-00000-00000-00000</span>
                </li>
                <li>
                  <b>Enterprise Core:</b>
                  <span>TDKQD-PKV44-PJT4N-TCJG2-3YJ6B</span>
                </li>
                <li>
                  <b>Strandard:</b>
                  <span>PHDV4-3VJWD-N7JVP-FGPKY-XBV89</span>
                </li>
                <li>
                  <b>Web:</b>
                  <span>WV79P-7K6YG-T7QFN-M3WHF-37BXC</span>
                </li>
              </ul>
            </div> */