import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import client from '../utils/shopify'
import "../css/ProfilePage.css"

function Profile() {
  const navigate = useNavigate()
  const [user, setUser] = useState([])

  const getUser = `
  query MyQuery($customerAccessToken: String!) {
  customer(customerAccessToken: $customerAccessToken) {
    displayName
    email
    firstName
    acceptsMarketing
  }
}`

  const loadUser = async () => {
    const customerAccessToken = localStorage.getItem('customerAccessToken')
    const { data, errors } = await client.request(getUser, {
      variables: {
        customerAccessToken: customerAccessToken
      },
    })
    setUser(data['customer']);
    console.log(data)
    console.log(errors)
  }

  useEffect(() => {
    if (localStorage.getItem('customerAccessToken') == null) {
      navigate('/register')
    } else {
      loadUser()
    }
  }, [])

  return (
    <div className='profile__page'>
      <h1>{user['displayName']}</h1>
      <h3>{user['email']}</h3>
      <h6>{}</h6>
      <button className='logout' style={{ marginBottom: '100px' }} onClick={() => { navigate('/cart') }}>VIEW CART</button>
      <button className='logout' onClick={() => { localStorage.removeItem('customerAccessToken'); navigate('/register') }}>LOGOUT</button>
    </div>
  )
}

export default Profile