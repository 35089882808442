import React, { useEffect, useState } from 'react'
import client from '../utils/shopify'
import "../css/Register.css"
import CURSOR from "../assets/cursor.png"
import Fade from 'react-reveal/Fade'
import { Link, useNavigate } from 'react-router-dom'
import Input from '../components/Input'
import { InfoOutlined } from '@mui/icons-material'

function Login() {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(null)
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState(null)
    const [loading, setLoading] = useState(false);

    const customerAccessToken = `
  mutation MyMutation($email: String!, $password: String!) {
    customerAccessTokenCreate(input: {email: $email, password: $password}) {
    customerUserErrors {
      code
      field
      message
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
  }
}
  `



    const createUser = async () => {
        //setLoading(true)
        if (email == '') { setEmailValid('invalid') } else { setEmailValid(null) }
        if (password == '') { setPasswordValid('invalid') } else { setPasswordValid(null) }
        if (email !== '' && password !== '') {

            try {
                const { data, error } = await client.request(customerAccessToken, {
                    variables: {
                        email: email,
                        password: password
                    }
                })
                console.log(email, password)
                console.log(data)
                localStorage.setItem('customerAccessToken', data['customerAccessTokenCreate']['customerAccessToken']['accessToken'])
                localStorage.setItem('expiresAt', data['customerAccessTokenCreate']['customerAccessToken']['expiresAt'])
                await navigate('/profile')
                setLoading(false)
                console.log(error)
            } catch (error) {
                setLoading(false)
                console.log(error)
            }

        } else {
            console.log('Fill all logs')
        }

    }

    useEffect(() => {
        if (localStorage.getItem('customerAccessToken') !== null) {
            navigate('/profile')

        }
    }, [])

    if (loading) return (
        <div className='loader'>
            <img src={CURSOR} />
        </div>
    )

    return (
        <div className='register__page'>
            <Fade bottom duration={1000}>
                <form onSubmit={(e) => { e.preventDefault(); createUser(); }}>
                    <p>LOGIN</p>
                    <span style={{ marginBottom: '25px' }}>Join our community</span>
                    <div className='inputs__container'>

                        <label style={{ color: emailValid === null ? 'black' : 'red' }}>EMAIL<input type='text' onChange={(e) => setEmail(e.target.value)} />{emailValid === null ? <></> : <span style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '8px' }}><InfoOutlined style={{ height: '20px' }} /> Required Field</span>} </label>
                        <label style={{ color: passwordValid === null ? 'black' : 'red' }}>PASSWORD<input type='text' onChange={(e) => setPassword(e.target.value)} />{passwordValid === null ? <></> : <span style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '8px' }}><InfoOutlined style={{ height: '20px' }} /> Required Field</span>}</label>
                    </div>
                    <button style={{ width: '100%', padding: '14px', marginBottom: '20px', color: 'white', background: 'black', border: 'none' }}>LOG INTO MY ACCOUNT</button>
                    <span>Not a member <span className='questionmark' style={{marginLeft: '-3px'}}>?</span> <Link style={{ color: 'black' }} to={'/register'}>REGISTER</Link></span>
                </form>
            </Fade>
        </div>
    )
}

export default Login