import React from 'react'
import INSIDEBOX from "../assets/InsideBox.mp4"
import INSIDE from "../assets/INSIDE.mov"
import { VideoScroll } from 'react-video-scroll'
import "../css/InsideBox.css"

const InsideTheBox = () => {

    return <div className='insidethebox'>
        <VideoScroll
        
            playbackRate={20}
            style={{ position: 'fixed', width: '100%' }}
        >
            <video
            className='video-holder'
                tabIndex="0"
                autobuffer="autobuffer"
                preload="preload"
                style={{ width: '100%', objectFit: 'contain' }}
                playsInline
            >
                <source type="video/mp4" src={INSIDE} />
            </video>
        </VideoScroll>
        <div className='bottomGradient'>
            <p>Scoll Down</p>
            <img src='https://images.squarespace-cdn.com/content/v1/5fcad2bbf5e79e668dd02b82/1612422442221-3NILQ7N2BKA2MK12BV8I/white-arrow-down.gif' />
        </div>
    </div>;
};
export default InsideTheBox;


/**        <video src={INSIDE} autoPlay muted style={{width: '100vw'}} preload controls/> */