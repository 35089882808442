import React, { useEffect, useState } from 'react'
import ArtImage from '../assets/ARTl.png'
import cursor from '../assets/cursor.png'
import '../css/ArtPage.css'
import { useRef } from 'react';
import gsap from 'gsap';
import locked from '../assets/silv.mov'
import silver from '../assets/movie.webm'
import { useGSAP } from '@gsap/react';
import { TimelineMax } from 'gsap/gsap-core';

gsap.registerPlugin(useGSAP);


function ArtPage() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(()=>{
        document.querySelector("meta[name='theme-color']").content = "#000000";
        return ()=>{
            document.querySelector("meta[name='theme-color']").content = "#E9E6E1";
        }
    },[])

    /*const dodgerRef = useRef(null);
    const dodgeDistance = 150;
    
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const dodger = dodgerRef.current;

        const dodgeCursor = (event) => {
            const cursorX = event.clientX;
            const cursorY = event.clientY;
            const dodgerRect = dodger.getBoundingClientRect();
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            const dodgerX = dodgerRect.left + dodgerRect.width / 2;
            const dodgerY = dodgerRect.top + dodgerRect.height / 2;

            const dx = cursorX - dodgerX;
            const dy = cursorY - dodgerY;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance < dodgeDistance) {
                const angle = Math.atan2(dy, dx);
                let offsetX = Math.cos(angle) * dodgeDistance;
                let offsetY = Math.sin(angle) * dodgeDistance;

                // Check boundaries and adjust if necessary
                const newX = dodgerRect.left + offsetX;
                const newY = dodgerRect.top + offsetY;

                if (newX < 0 || newX + dodgerRect.width > viewportWidth) {
                    console.log(newX, dodgerRect.width)
                    offsetX = +window.innerWidth / 2;
                }
                if (newY < 0 || newY + dodgerRect.height > viewportHeight) {
                    console.log(newY, dodgerRect.height)
                    offsetY = +window.innerHeight / 2;
                }

                gsap.to(dodger, {
                    x: `-=${offsetX}`,
                    y: `-=${offsetY}`,
                    duration: 0.5,
                    ease: 'back.out'
                });
            }
        };

        const leaveCursor = () => {
            gsap.to(dodger, {
                x: 0,
                y: 0,
                ease: 'back.out',
                duration: 1.5
            })
        }

        document.addEventListener('mousemove', dodgeCursor);
        document.addEventListener('mouseleave', leaveCursor);

        return () => {
            document.removeEventListener('mousemove', dodgeCursor);
            document.addEventListener('mouseleave', leaveCursor);
        };
    }, []);*/

    useGSAP(() => {
        const tlCube = new TimelineMax({ repeat: -1 });
        tlCube.to('.gunshot', 1, { y: '-=20', })
            .to('.gunshot', 1, { y: '+=20', })

    })

    return (
        <div className='artpage' style={{ backgroundColor: '#000000' }}>

            <div style={{ position: 'absolute', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '50px' }}>
                <div style={{
                    position: 'absolute',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    color: 'white',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                }}>
                    <video muted
                        autoPlay
                        loop playsInline src={locked} className='gunshot' width={width >= 1000 ? 600 : 400} />
                    <div style={{textAlign: 'center'}}>
                        <h1>LOCKED!</h1>
                        <h2>Member's access only</h2>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ArtPage

/**
    <img width={width} src='https://www.ledr.com/colours/black.jpg' />        */