import React from 'react'
import NoVideo from "../assets/novideo.mp4"
import { Link } from 'react-router-dom';
import { GoArrowUpLeft } from "react-icons/go";

const NoMatch = () => {
    return (
    <div style={{ width: '100vw', display: "flex", justifyContent: 'center', alignItems: 'center' }} >
        <video src={NoVideo} autoPlay muted loop />
        <Link to='/' style={{position: 'absolute',bottom: '20px',color: 'black',background: 'white',padding: '8px', borderRadius: '50px',textDecoration: 'none',fontSize: '20px',display: 'flex',alignItems: 'center'}}><GoArrowUpLeft size={22} />  GO HOME</Link>
    </div>);
};
export default NoMatch;


/**<img src='https://colorlib.com/wp/wp-content/uploads/sites/2/404-error-template-3.png'/> */

/** <div style={{display:"flex",flexDirection: 'column',flexWrap: 'wrap' ,justifyContent: 'center', alignItems: 'center'}}>
            <p>OOPS! PAGE NOT FOUND</p>
            <h1 style={{fontSize: '200px'}}>404</h1>
            <span>WE ARE SORRY, THESE ARE UNCHARTED TERRITORY</span>
        </div>
         */