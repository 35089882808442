import { Search } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import "../css/SearchComponent.css"
import searchIcon from "../assets/search.png"
import client from '../utils/shopify'
import { Link } from 'react-router-dom'

function SearchComponent({ on, setOn }) {
  const [query, setQuery] = useState('')
  const [searchProduct, setSearchProduct] = useState([])

  const seachQuery = `
    query suggestions($query: String!) {
  predictiveSearch(query: $query, searchableFields: TITLE) {
    queries {
      text
    }
    products {
      images(first: 1) {
        nodes {
          src
        }
      }
      title
      handle
    }
  }
}`

  const fetchSearch = async () => {
    const { data, error } = await client.request(seachQuery, {
      variables: {
        query: query
      }
    })
    console.log(data)
    setSearchProduct(data['predictiveSearch']['products'])
  }

  useEffect(() => {
    fetchSearch()
  }, [query])

  if (!on) return (<div style={{ position: 'absolute', right: '0px', top: window.innerWidth > 1000 ? "2px" : '30px', right: window.innerWidth > 1000 ? '110px' : '60px', cursor: 'pointer' }} onClick={() => { setOn(true); fetchSearch() }}>
    <img width={window.innerWidth > 1000 ? '18px' : ' 25px'} src={searchIcon} /></div>)

  return (

    <div className='search__background' onClick={()=>setOn(false)} style={{ backdropFilter: on ? 'blur(15px)' : '', zIndex: on ? 1200 : 0 }} >

      <div className='search__components' style={{ position: 'absolute', top: window.innerWidth > 1000 ? '30px' : '20px', right: window.innerWidth > 1000 ? '120px' : '60px' }}>
        <div className='search__controller' style={{ position: 'absolute', right: '0px', top: window.innerWidth > 1000 ? "-10px" : '0px', cursor: 'pointer' }}   ><input autoFocus placeholder='Search...' onChange={(e) => setQuery(e.target.value)} /><button onClick={() => { setOn(false); setQuery(''); }}>X</button></div>

        {
          !on
            ? <></>
            : <div className='search__results' style={{ width: window.innerWidth > 1000 ? "50vw" : "300px", right: window.innerWidth > 1000 ? '120px' : '10%' }}>
              <div className='search__result'>
                {searchProduct.map((searchItem) => (
                  <Link onClick={() => setOn(!on)} to={`/products/${searchItem['handle']}`} style={{ textDecoration: 'none' }}>
                    <div className='search__card'><img width={100} src={searchItem['images']['nodes'][0]['src']} /><h5>{searchItem['title']}</h5></div>
                  </Link>

                ))}
                {
                  query !== '' && searchProduct.length == 0 ? <h1>No results found.</h1> : <></>
                }
              </div>
            </div>
        }

      </div>
    </div>
  )
}

export default SearchComponent